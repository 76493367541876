import React from 'react';
import './AutoMl.css';

function AutoMl() {
  return (
  <div>AutoMl</div>
);
}

export default AutoMl;
