import React, { useEffect, useState } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import "./index.css";
import Contact from "./pages/Contact";
import Home from "./pages/Home";
import AutoMl from "./pages/ProjectDetails/AutoMl";
import FitMyJob from "./pages/ProjectDetails/FitMyJob";
import ScorePredictor from "./pages/ProjectDetails/ScorePredictor";
import Projects from "./pages/Projects";
import Resume from "./pages/Resume";



function App() {
  const [showNavbar, setShowNavbar] = useState(false); // Controls Navbar visibility
  const [activeTab, setActiveTab] = useState("home");
  const location = useLocation();
  const navigate = useNavigate();

  // Handle Navbar visibility based on the current path
  useEffect(() => {
    if (location.pathname === "/") {
      setShowNavbar(false); // Hide Navbar on the home page
    } else {
      setShowNavbar(true); // Show Navbar on other pages
    }
  }, [location]);

  return (
    <div className="App flex flex-col bg-transparent min-h-screen">
      {/* Conditionally render the Navbar */}
      {showNavbar && (
        <Navbar activeTab={activeTab} setActiveTab={setActiveTab} />
      )}
      <Routes>
        <Route
          path="/"
          element={
            <Home setShowNavbar={setShowNavbar} setActiveTab={setActiveTab} />
          }
        />
        <Route
          path="/resume"
          element={
            <Resume setShowNavbar={setShowNavbar} setActiveTab={setActiveTab} />
          }
        />
        <Route
          path="/projects"
          element={
            <Projects
              setShowNavbar={setShowNavbar}
              setActiveTab={setActiveTab}
            />
          }
        />
        <Route
          path="/contact"
          element={
            <Contact
              setShowNavbar={setShowNavbar}
              setActiveTab={setActiveTab}
            />
          }
        />
        <Route
          path="projects/fit-my-job"
          element={
            <FitMyJob
              setShowNavbar={setShowNavbar}
              setActiveTab={setActiveTab}
            />
          }
        />
        <Route
          path="projects/score-predictor"
          element={
            <ScorePredictor
              setShowNavbar={setShowNavbar}
              setActiveTab={setActiveTab}
            />
          }
        />
        <Route
          path='projects/automl'
          element={
            <AutoMl setShowNavbar={setShowNavbar} setActiveTab={setActiveTab} />
          }
        />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
